import React from "react";
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from '../components/loading-spinner';

const QrRoute = Loadable({
  loader: () => import('./qr/qr.route'),
  loading: Loading,
});

const AboutRoute = Loadable({
  loader: () => import('./about/about.route'),
  loading: Loading,
});

const InputRoute = Loadable({
  loader: () => import('./input/input.route'),
  loading: Loading,
});

const RateRoute = Loadable({
  loader: () => import('./rate/rate.route'),
  loading: Loading,
});

const ErrorRoute = Loadable({
  loader: () => import('./error/error.route'),
  loading: Loading,
});

const PaymentRoute = Loadable({
  loader: () => import('./payment/payment.route'),
  loading: Loading,
});

const FAQRoute = Loadable({
  loader: () => import('./FAQ/faq.route'),
  loading: Loading,
});

const AppealRoute = Loadable({
  loader: () => import('./appeal/appeal.route'),
  loading: Loading,
});

const LockerRoute = Loadable({
  loader: () => import('./locker/locker.route'),
  loading: Loading,
});

const NotFound = () => <h2>NotFound</h2>;

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/open/:orderCode" component={AboutRoute} exact/>
        <Route path="/qr/:orderCode" component={QrRoute}  />
        <Route path="/input/:orderCode" component={InputRoute} />
        <Route path="/rate/:orderCode" component={RateRoute} />
        <Route path="/error/:orderCode" component={ErrorRoute} />
        <Route path="/payment/:orderCode" component={PaymentRoute} />
        <Route path="/locker/:orderCode" component={LockerRoute} />
        <Route path="/faq" component={FAQRoute} />
        <Route path="/appeal" component={AppealRoute} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
